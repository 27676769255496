import React from 'react';

export default function AboutText(){
  return(
    <>
    <h1 id="abouthead">Tom Johnson</h1>
    <h4>Tom's the Bomb</h4>
    <p>Honesty and integrity, along with determination and dedication, are philosophies that Tom strives for in all aspects of his life. Applying these principles to his business has allowed him to be a top producing real estate agent for the past 19 years. His background as a commercial contractor gives him an edge in appraising and generating home value for his buyers and sellers.</p>
    <p>Tom has a proven record of directly impacting his client’s financial success and propelling smooth transactions by applying refined management, selling, and negotiation talents. If you are planning on buying or selling in the New Castle area contact Tom, your local neighborhood expert for a personalized customer care. Tom takes pride in servicing his clients by delivering exceptional service and establishing lifelong relationships.</p>
    </>
  )
}
