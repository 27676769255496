import React, {Component} from 'react';
import Slider from './components/slider';
import Mainnav from './components/mainnav';
import Backtext from './components/BackpageMain';
import Featurenav from './components/featurenav';
import Shoe from './components/shoe';
import Divider from './components/Divider';
import stat from './images/static.webp';

export default class Backpage1 extends Component {
  render(){
    return(
      <>
      <img src={stat} alt="Beautiful Delaware" id="headerimg" />
      <Mainnav />
      <Backtext />
      <Divider />
      <div className="container" id="infotext">
        <h1>Sellers Representation Heritage Park DE</h1>
        <h4>Let the Delaware Real Estate Hub represent your home sale in Heritage Park, DE.</h4>
        <p>Those looking for seller’s representation in Heritage Park find out fast that they need not continue their search. With us, we can value, list, and then sell your house anywhere throughout Heritage Park. Not only that, but we’ll also work tirelessly and patiently to ensure that we can sell your home for maximum value. We want you to walk away happy and comfortable. And with our knowledge and professional assistance, you have the upper hand.</p>
        <p>While supporting your goals, we’ll also consider the needs and wants of a typical buyer in this town. Serving both parties’ needs is advantageous to a quicker sale at a higher profit. As a professional expert with unparalleled insight, I treat everyone exceptionally, and I know the area intimately. Buyers will find this village-style area to be just what they’re looking for, but I want them to feel the same way about your home too.</p>
        <p>Don’t keep hunting for seller’s representation in Heritage Park DE when the best is right here waiting. With a structural and amenity home inspection, staging advice, and alluring curb appeal design, we’ll run a free CMA to create a fair and competitive price to list your home. And we’ll make your listing accessible by using the latest technology like email marketing, social media campaigns, MLS visibility, and real estate website prominence.</p>
        <p>Before you know it, you’ll get plenty of competitive offers and a sale for optimal return. We are the Delaware Real Estate Hub, and we are extraordinarily accommodating and willing specialists, we’re happy to help with whatever service you require. We provide superior results, and we’re prepared to offer you more when it comes to quality services. Get in touch with us today to begin your successful home selling process.</p>
      </div>
      <Shoe />
      </>
    )
  }
}
