import React, {useState} from 'react';
import {Form, Modal, Button} from 'react-bootstrap';

export default function Capture() {
  const [show, setShow] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMsg] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleName = (e) => setName(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);
  const handlePhone = (e) => setPhone(e.target.value);
  const handleMsg = (e) => setMsg(e.target.value);
  const handleSubmit = () => {
    const pkg = {
      name: name,
      email: email,
      phone: phone,
      msg: message
    }
    fetch('/capture', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify(pkg)
    })
    .then((res) => res.json())
    .then((data) => {
      console.log("Success", data)
    })
  }

  return(
    <>
    <Button variant="dark" onClick={handleShow} id="capturebtn">Learn More</Button>
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Contact Tom Today!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="captureFormName">
            <Form.Label>Your Name:</Form.Label>
            <Form.Control type="text" onChange={handleName} />
          </Form.Group>
          <Form.Group controlId="captureFormEmail">
            <Form.Label>Your Email:</Form.Label>
            <Form.Control type="email" onChange={handleEmail} />
          </Form.Group>
          <Form.Group controlId="captureFormPhone">
            <Form.Label>Your Phone Number:</Form.Label>
            <Form.Control type="text" onChange={handlePhone} />
          </Form.Group>
          <Form.Group controlId="captureFormMsg">
            <Form.Label>Message:</Form.Label>
            <Form.Control as="textarea" rows="3" onChange={handleMsg} />
          </Form.Group>
          <Button variant="success" type="submit" onClick={handleSubmit}>Send</Button>
        </Form>
      </Modal.Body>
    </Modal>
    </>
  )
}
