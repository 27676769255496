import React, {Component} from 'react';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import logo from '../images/exlogo.png';
import Capture from './captureform';
import Connect from './connectmodal';

class Mainnav extends Component {
  render(){
    return(
      <>
        <Navbar bg="secondary" expand="lg" id="mainnav">
          <div className="container">
          <Navbar.Brand href="/"><img src={logo} alt="RE Logo" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="main-nav" />
          <Navbar.Collapse id="main-nav">
            <Nav className="ml-auto">
              <NavDropdown title="Featured Articles" id="feature-drop">
                <NavDropdown.Item><Link to="/real-estate-specialist-westover-hills">Westover Hills Real Estate Specialist</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to="/bellefonte-luxury-homes">Luxury Homes in Bellefonte</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to="/buyers-representation-brookside">Buyers Representation in Brookside</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to="/sell-my-home-fast-bear">Sell My Home Fast in Bear</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to="/sell-my-home-north-star">Sell My Home in North Star</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to="/whats-my-home-worth-bellefonte">What's My Home Worth in Bellefonte?</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to="/listing-specialist-bellefonte">Listing Specialist Bellefonte</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to="/free-market-analysis-pennyhill-terrace">Free Market Analysis Pennyhill Terrace</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to="/buyers-agent-19803">Buyers Agent in 19803</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to="/buyers-representation-19804">Buyers Representation in 19804</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to="/free-market-analysis-ashley-heights">Free Market Analysis Ashley Heights</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to="/sellers-representation-heritage-park">Sellers Representation Heritage Park</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to="/sellers-agent-pennyhill-terrace">Sellers Agent in Pennyhill Terrace</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to="/buyers-specialist-19809">Buyers Specialist in 19809</Link></NavDropdown.Item>
              </NavDropdown>
              <Link to="/featuredarea" className="nav-link">Featured Area</Link>
              <Link to="/buyers" className="nav-link">Buyers</Link>
              <Link to="/sellers" className="nav-link">Sellers</Link>
              <NavDropdown title="About" id="main-drop-about">
                <NavDropdown.Item><Link to="/aboutme">About Tom</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to="/company">About DREH</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to="/aboutarea">About New Castle County</Link></NavDropdown.Item>
              </NavDropdown>
              <Connect />
            </Nav>
          </Navbar.Collapse>
          </div>
        </Navbar>
      </>
    )
  }
}

export default Mainnav;
