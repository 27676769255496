import React, {Component} from 'react';
import Mainnav from './components/mainnav';
import Shoe from './components/shoe';
import Divider from './components/Divider';
import area from './images/majorarea.jpg';
import MAreaInfo from './components/MAreaInfo';
import MareaText from './components/Mareatext';
import Capture from './components/captureform';

export default function Marea() {
  return(
    <>
    <Mainnav />
    <img src={area} alt="Area Name" id="fareaimg" />
    <Divider />
    <div className="container">
      <MAreaInfo />
      <MareaText />
      <div id="capture">
        <Capture />
      </div>
    </div>
    <Shoe />
    </>
  )
}
