import React from 'react';

export default function FareaText() {
  return(
    <>
    <h1 id="fhometitle">BEAR, DE</h1>
    <h4>Small town living close to the city.</h4>
    <p>Only 14 miles south of Wilmington, the town of Bear provides a country living feel, while still being close to the big city. If you've ever wanted to get away from the hustle and bustle of city life, but still want access to the nightlife and entertainment options a city provides, Bear is a great choice.</p>
    <p>If you're looking for a home with lots of space to grow, and lots of land to explore, Bear should be very high on your list. Many of the homes and properties here have expansive floorplans and lots, many with creeks and wooded areas to "get back to nature" and escape the traffic and "concrete jungle" that comes with living in the city.</p>
    <p>Bear is also a great place to raise your family, with highly rated schools (public and private), and tons of history for interesting day trips for kids and adults. It's also close to the Delaware Bay, so a beach trip is a great idea for a family outing.</p>
    <p>Come to Bear, and experience living among nature, while still living close to the city!</p>
    </>
  )
}
