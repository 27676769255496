import React from 'react';
import Mainnav from './components/mainnav';
import Capture from './components/captureform';
import Shoe from './components/shoe';
import BuyersLeft from './components/buyleft';
import BuyersRight from './components/buyright';
import buyers from './images/buyers.jpeg';

export default function Buyers() {
  return(
    <>
    <Mainnav />
    <div className="container" id="buyers">
      <img src={buyers} alt="Home Buyers" id="buyimg" />
      <hr />
      <BuyersLeft />
      <hr />
      <BuyersRight />
      <div id="btnbox">
        <Capture />
      </div>
    </div>
    <Shoe />
    </>
  )
}
