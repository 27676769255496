import React, {Component} from 'react';
import {Carousel} from 'react-bootstrap';
import farea1 from '../images/farea1.jpg';
import farea2 from '../images/farea2.jpg';
import farea3 from '../images/farea3.jpg';

class AreaSlider extends Component {
  render(){
    return(
      <>
      <Carousel id="areaslider">
        <Carousel.Item>
          <img className="d-block w-100" src={farea1} alt="New Castle Landmarks" />
          <Carousel.Caption>
            <h4>New Castle County</h4>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={farea2} alt="Wilmington, DE" />
          <Carousel.Caption>
            <h4>Wilmington, DE</h4>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={farea3} alt="Bear Historic Homes" />
          <Carousel.Caption>
            <h4>Bear, DE</h4>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      </>
    )
  }
}

export default AreaSlider;
