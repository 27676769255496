import React, {Component} from 'react';
import area from '../images/majorareasm.jpg';
import {Card, ListGroup} from 'react-bootstrap';

class Backtext extends Component {
  render(){
    return(
      <>
      <div className="container" id="backtext">
        <div className="row">
          <div className="col-4">
            <img src={area} alt="Keyterm" id="backimg" />
          </div>
          <div className="col-8">
            <h4>New Castle County, Delaware</h4>
            <p>New Castle County is the northernmost county in Delaware. It is the state's most populated county, with just under 60% of the state's population residing within. In New Castle County, we have two minor league sports teams, the Wilmington Blue Rocks, and the Delaware Blue Coats, so there's always a game to attend.</p>
            <p>As part of the first official state in the US, New Castle County has been incorporated since 1637. Due to our long history, we have diverse demographics, including Lenape Native Americans, Swedish, Dutch, English, and Spanish ancestry. We're home to Wilmington, Newark, New Castle, and Delaware City.</p>
          </div>
        </div>
      </div>
      </>
    )
  }
}

export default Backtext;
