import React, {Component} from 'react';
import Slider from './components/slider';
import Mainnav from './components/mainnav';
import Backtext from './components/BackpageMain';
import Featurenav from './components/featurenav';
import Shoe from './components/shoe';
import Divider from './components/Divider';
import stat from './images/static.webp';

export default class Backpage1 extends Component {
  render(){
    return(
      <>
      <img src={stat} alt="Beautiful Delaware" id="headerimg" />
      <Mainnav />
      <Backtext />
      <Divider />
      <div className="container" id="infotext">
        <h1>Real Estate Specialist Westover Hills, DE</h1>
        <h4>Find a real estate specialist in Westover Hills, DE.</h4>
        <p>If you're looking for a real estate specialist in Westover Hills, DE, we're here to help you. Our clients achieve all of their goals with their buying and selling real estate needs when they work with me as we specialize in many facets of the market. Contact me today and allow me to help you gain the things you need for a successful purchase or sale in this real estate market.</p>
        <p>There are only a limited number of homes available in Westover Hills, DE. so don't go at it alone when you set out to purchase or are looking to sell in this prestigious area, let the top real estate specialist in Westover Hills, DE, be your guide. My internet portal has additional information, and it's for these reasons I'm someone you'll always want to work alongside in your local real estate market. Learning ahead of time from a seasoned professional will turn your life around. Communicate with me today for additional information.</p>
        <p>When you are in need of a real estate specialist in Westover Hills, DE. I will help fully understand all of your options when you are  determining what all you seek in a piece of property. My efforts to serve you will drive my approach, and you'll find out more about what you need to know when you visit my website. It's the ideal time to purchase the new house of your dreams.</p>
        <p>Get someone experienced to help you here. The median price here is $564,000 or $193 per Sq Ft. I'm delighted to schedule a consultation with you at your earliest convenience, and you'll be able to see for yourself while people make me their go-to real estate agent. You need a Real Estate Professional like no other, who'll serve you first, treating you like an individual. I'm up for the task. There's no one else who'll provide the same level of knowledge and expertise while you navigate the New Castle County market, so schedule your free consultation with me, and you'll get the results you want.</p>
      </div>
      <Shoe />
      </>
    )
  }
}
