import React, {Component} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Home from './App';
import Backpage1 from './backpage1';
import Backpage2 from './backpage2';
import Backpage3 from './backpage3';
import Backpage4 from './backpage4';
import Backpage5 from './backpage5';
import Backpage6 from './backpage6';
import Backpage7 from './backpage7';
import Backpage8 from './backpage8';
import Backpage9 from './backpage9';
import Backpage10 from './backpage10';
import Backpage11 from './backpage11';
import Backpage12 from './backpage12';
import Backpage13 from './backpage13';
import Backpage14 from './backpage14';
import Fhome from './featuredhome';
import Farea from './featuredarea';
import AboutMe from './aboutme';
import Company from './company';
import Marea from './majorarea';
import Buyers from './buyers';
import Sellers from './sellers';

export default function Routes() {
  return(
    <Router>
      <Switch>
        <Route exact path="/sellers">
          <Sellers />
        </Route>
        <Route exact path="/buyers">
          <Buyers />
        </Route>
        <Route exact path="/aboutarea">
          <Marea />
        </Route>
        <Route exact path="/company">
          <Company />
        </Route>
        <Route exact path="/aboutme">
          <AboutMe />
        </Route>
        <Route exact path="/featuredarea">
          <Farea />
        </Route>
        <Route exact path="/featuredhome">
          <Fhome />
        </Route>
        <Route exact path="/sellers-representation-heritage-park">
          <Backpage14 />
        </Route>
        <Route exact path="/free-market-analysis-ashley-heights">
          <Backpage13 />
        </Route>
        <Route exact path="/buyers-representation-19804">
          <Backpage12 />
        </Route>
        <Route exact path="/buyers-specialist-19809">
          <Backpage11 />
        </Route>
        <Route exact path="/buyers-agent-19803">
          <Backpage10 />
        </Route>
        <Route exact path="/sellers-agent-pennyhill-terrace">
          <Backpage9 />
        </Route>
        <Route exact path="/free-market-analysis-pennyhill-terrace">
          <Backpage8 />
        </Route>
        <Route exact path="/listing-specialist-bellefonte">
          <Backpage7 />
        </Route>
        <Route exact path="/whats-my-home-worth-bellefonte">
          <Backpage6 />
        </Route>
        <Route exact path="/buyers-representation-brookside">
          <Backpage5 />
        </Route>
        <Route exact path="/sell-my-home-north-star">
          <Backpage4 />
        </Route>
        <Route exact path="/sell-my-home-fast-bear">
          <Backpage3 />
        </Route>
        <Route exact path="/bellefonte-luxury-homes">
          <Backpage2 />
        </Route>
        <Route exact path="/real-estate-specialist-westover-hills">
          <Backpage1 />
        </Route>
        <Route exact path="/capture">
          <Home />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  )
}
