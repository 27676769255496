import React, {Component} from 'react';
import Slider from './components/slider';
import Mainnav from './components/mainnav';
import Backtext from './components/BackpageMain';
import Featurenav from './components/featurenav';
import Shoe from './components/shoe';
import Divider from './components/Divider';
import stat from './images/static.webp';

export default class Backpage1 extends Component {
  render(){
    return(
      <>
      <img src={stat} alt="Beautiful Delaware" id="headerimg" />
      <Mainnav />
      <Backtext />
      <Divider />
      <div className="container" id="infotext">
        <h1>Buyers Agent 19803</h1>
        <h4>Do you need the services of the best buyer’s agent in 19803?</h4>
        <p>If you want the top buyer’s agent in 19803, you’ve found the right experts to help. The principles of integrity and reliability as local professionals allow Delaware Real Estate Hub to focus attention on our clients’ needs in an efficient and authentic style unparalleled throughout New Castle County. Let our neighborhood and industry knowledge help guide you through the purchase process.</p>
        <p>Choosing us means getting access to the relevant MLS listings in the area. You’ll see something for everyone among the selection in this area spanning across New Castle County. Home listings range from $150k to $800k. Perhaps you’re looking for an investment property to rent out. Or maybe you’re more interested in a second home on the waterfront getaway. No matter your wants and needs, you’ll find your next purchase here.</p>
        <p>When you find yourself in need of a buyer’s agent in 19803, we’ll be here to answer the call. And we’ll offer you every resource and tool of the trade we have when you’re ready to purchase. Getting the home that you want requires knowing the ins and outs of the market, area, and each home available. When you need that pertinent info, including where to find the facilities and amenities most valuable to you, we have the answers. Our help gives you every advantage.</p>
        <p>We specialize in real estate and top-tier service. When you need only the best to help you through the complicated buying processes, we can help you reach your goals. Your comfort and lowered stress levels are valuable to us, as is your friendship and trust. Let’s talk more about your wants and needs so that we can start with a CMA and also scan the latest MLS listings for properties that interest you. Connect with us soon to begin.</p>
      </div>
      <Shoe />
      </>
    )
  }
}
