import React, {Component} from 'react';
import Slider from './components/slider';
import Mainnav from './components/mainnav';
import Backtext from './components/BackpageMain';
import Featurenav from './components/featurenav';
import Shoe from './components/shoe';
import Divider from './components/Divider';
import stat from './images/static.webp';

export default class Backpage1 extends Component {
  render(){
    return(
      <>
      <img src={stat} alt="Beautiful Delaware" id="headerimg" />
      <Mainnav />
      <Backtext />
      <Divider />
      <div className="container" id="infotext">
        <h1>Buyers Specialist 19809</h1>
        <h4>I need the top buyer’s specialist in 19809 to show me the homes for sale.</h4>
        <p>When you need a buyer’s specialist in 19809 to show you its real estate, see local real estate pros like us. We’re experts who have unmatched area knowledge and a wealth of experience in the real estate business, and we help buyers of all kinds accomplish their real estate goals throughout this Wilmington DE area. Whatever assistance you may need, we’re confident we can provide it. We are the Delaware Real Estate Hub, and the Delaware Real Estate Hub is at your service.</p>
        <p>Which neighborhood do you like best? What are nearby facilities that are essential to you? Are schools, your workplace, transportation, conveniences, or anything else relevant to your search? Clearly defining your wants and needs means developing an action strategy to prepare. Going into a home purchase is risky, especially since it’s one of the most significant investments you’ll ever make. But we can help you figure out what works best.</p>
        <p>And as your buyer’s specialist in 19809, I pledge to put you in the home you desire most with the backing of my team. Let’s scan the latest MLS listings together and compile a list of homes you want to see once you’ve narrowed down your options. We'll tell you about community amenities, local events, freeway access points, and all your shopping, dining, entertainment, and recreation options or anything else you deem relevant.</p>
        <p>Whether you’re looking for houses from $150K to $800k or land from $50K to $2M, we’re the most practical choice to be your representation during your search. It’s critical to choose qualified specialists with knowledge, information, communication, presence, and expert negotiation skills. Find out how we dedicate ourselves to your goals and call us today.</p>
      </div>
      <Shoe />
      </>
    )
  }
}
