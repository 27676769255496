import React, {Component} from 'react';
import realtor from '../images/realtor.png';
import {Card, ListGroup} from 'react-bootstrap';

class Maintext extends Component {
  render(){
    return(
      <>
      <div className="container" id="maintext">
        <div className="row">
          <div className="col-3">
            <Card style={{ width: '14rem' }}>
              <Card.Img variant="top" src={realtor} />
              <Card.Body>
                <Card.Title>Tom Johnson</Card.Title>
                <ListGroup variant="flush">
                  <ListGroup.Item>Experience: 19 years</ListGroup.Item>
                  <ListGroup.Item>Avg Price: $490,502</ListGroup.Item>
                  <ListGroup.Item><a href="tel:1234567890">123-456-7890</a></ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </div>
          <div className="col-xl-9 .col-lg-12">
            <h4>"My clients are what makes me great."</h4>
            <p>Tom Johnson has 19 years experience in real estate, and serves as a liason to other real estate professionals on using technology to improve their customer care, general marketing, and office management.</p>
            <p>If you are planning on buying or selling in the New Castle area contact Tom, your local neighborhood expert for a personalized customer care. Tom takes pride in servicing his clients by delivering exceptional service and establishing lifelong relationships.</p>
          </div>
        </div>
      </div>
      </>
    )
  }
}

export default Maintext;
