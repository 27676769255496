import React from 'react';
import sellleft from '../images/sellleft.jpg';

export default function SellersLeft(){
  return(
    <>
    <div className="container" id="buybox">
      <div className="row">
        <div className="col-4">
          <img src={sellleft} alt="Home Buyers" id="buyleft" />
        </div>
        <div className="col-8">
          <h1>How Not To Sell Your Home</h1>
          <h4>Avoid these common pitfalls if you want to sell your home fast.</h4>
          <p>Most buyers start their home search online and might skip your home completely if they spot something they dislike in the online pictures. Here are some things I've found to avoid having in your home's listing.</p>
          <p><strong>Garage/Bedroom Conversions</strong></p>
          <p>While converting a garage or bedroom into media studios, gyms, or maker spaces might sound like a great idea, it's not if you plan on selling your home. Buyers are looking for space, not stuff, and while you might find a niche buyer that would love your woodworking studio, you're losing out on the market in general when they see a space they can't use for their needs.</p>
          <p><strong>Carpeting Over Hardwood</strong></p>
          <p>A lot of potential buyers are looking for hardwood instead of carpeting these days. So if you have carpet over your hardwood, maybe it's time to return to the original.</p>
          <p><strong>Bold Fixtures</strong></p>
          <p>Most buyers want to see a "blank slate" that they can customize to their style, so if you have any bold fixtures, elaborate decorations, or "loud" window dressing, consider swapping them out for simpler styles, so potential buyers can envision their style in the space.</p>
        </div>
      </div>
    </div>
    </>
  )
}
