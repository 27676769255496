import React, {Component} from 'react';
import Mainnav from './components/mainnav';
import Shoe from './components/shoe';
import Divider from './components/Divider';
import home from './images/slide3.jpg';
import HomeInfo from './components/HomeInfo';
import HomeFeatures from './components/HomeFeatures';
import FhomeText from './components/Fhometext';
import Capture from './components/captureform';

export default function Fhome() {
  return(
    <>
    <Mainnav />
    <img src={home} alt="Area Name" id="fareaimg" />
    <Divider />
    <div className="container">
      <HomeInfo />
      <HomeFeatures />
      <FhomeText />
      <div id="capture">
        <Capture />
      </div>
    </div>
    <Shoe />
    </>
  )
}
