import React from 'react';

export default function MareaText() {
  return(
    <>
    <h1 id="fhometitle">New Castle County</h1>
    <h4>Home of the first permanent European settlement on Delaware soil.</h4>
    <p>New Castle County, the northernmost county in Delaware, is home to the majority of the state's population. Over 523,000 people live here, spread across the county, making for a great place to live if you want a balance of city living and that rustic feel that living away from urban areas provides.</p>
    <p>The Delaware Real Estate Hub can help you find and purchase a home in New Castle, Wilmington, Bear, Bellefonte, or many other towns within New Castle County. Our agents can provide the local expertise you need to make the most informed decision possible when moving to or within this great area.</p>
    <p>The area is also full of American history, such as the first permanent European settlement in Delaware, the historic Fort Christina, and New Castle County was where the famous William Penn landed when he came to the Americas. If you're into swimming, fishing, boating, or any other water-based activities, New Castle County is 13.8% water, including the Christina River, the Delaware River,  and Sunset Lake. So come and enjoy the unique style of living we provide, where you can fish in the morning, work during the day, and go to the nightclub at night, all within a short drive of each other.</p>
    </>
  )
}
