import React from 'react';
import Mainnav from './components/mainnav';
import Capture from './components/captureform';
import Shoe from './components/shoe';
import SellersLeft from './components/sellleft';
import SellersRight from './components/sellright';
import sellers from './images/sellers.jpeg';

export default function Sellers() {
  return(
    <>
    <Mainnav />
    <div className="container" id="sellers">
      <img src={sellers} alt="Home Sellers" id="sellimg" />
      <hr />
      <SellersRight />
      <hr />
      <SellersLeft />
      <div id="btnbox">
        <Capture />
      </div>
    </div>
    <Shoe />
    </>
  )
}
