import React, {Component} from 'react';
import Slider from './components/slider';
import Mainnav from './components/mainnav';
import Backtext from './components/BackpageMain';
import Featurenav from './components/featurenav';
import Shoe from './components/shoe';
import Divider from './components/Divider';
import stat from './images/static.webp';

export default class Backpage6 extends Component {
  render(){
    return(
      <>
      <img src={stat} alt="Beautiful Delaware" id="headerimg" />
      <Mainnav />
      <Backtext />
      <Divider />
      <div className="container" id="infotext">
        <h1>What's My Home Worth Bellefonte, DE</h1>
        <h4>How can I find the value of my home in Bellefonte, DE?</h4>
        <p>"Tell me now, what's my home worth in Bellefonte DE If you want to sell your property, knowing what it's worth ahead of time is one of the essential parts of the process. I'm happy to tell you more about current market conditions, and you'll soon see more about what you need to know when preparing your home for sale and pricing. It doesn't need to be a challenge, and I'm pleased to work with you.The value of your home will no longer be a mystery. There are currently 9 homes on the market. One of my goals is to make your property stand out from the rest. Competitive pricing reflecting recent marketing trends is one of the most critical factors here. For instance, what market state are we in here? Ideal conditions are a hot market which factors those selling.</p>
        <p>"I need to know now, what's my home worth in Bellefonte DE!" You'll get your answer quickly when I handle your research. Guessing games aren't suitable for determining what your property's value is. Listing too high will cause people to look elsewhere and lose interest. Alternatively, a dollar figure too low means you'll sell quickly, but you won't get anywhere near what you could with a professional seller's agent.</p>
        <p>Learn what your house is worth with my help. The local median price is $235,000 you deserve every dollar your home is worth, and that's why I'll work with you all the way through. Learning more shouldn't be a hassle, and I want to help you in every way I possibly can. Call today to arrange a courtesy consultation, and you'll find out why I'm the top mind on the local market to price and sell on your behalf!</p>
      </div>
      <Shoe />
      </>
    )
  }
}
