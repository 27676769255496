import React from 'react';
import './App.css';
import Mainnav from './components/mainnav';
import Maintext from './components/maintext';
import Featurenav from './components/featurenav';
import Secondtext from './components/secondtext';
import Shoe from './components/shoe';
import AreaSlider from './components/AreaSlider';
import stat from './images/static.webp';

function Home() {
  return (
    <div className="App">
      <img src={stat} alt="Beautiful Delaware" id="headerimg" />
      <Mainnav />
      <Maintext />
      <AreaSlider />
      <Secondtext />
      <Shoe />
    </div>
  );
}

export default Home;
