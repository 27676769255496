import React from 'react';
import {Card, ListGroup} from 'react-bootstrap';

export default function AreaInfo() {
  return(
    <>
    <div className="container" id="homeinfo">
      <div className="row">
        <Card style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>Population Info</Card.Title>
            <Card.Subtitle>Bear, DE</Card.Subtitle>
            <ListGroup variant="flush">
              <ListGroup.Item>Total Population: 20,401</ListGroup.Item>
              <ListGroup.Item>Workforce: 10,200</ListGroup.Item>
              <ListGroup.Item>Homeownership: 61.8%</ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
        <Card style={{width:'18rem'}}>
          <Card.Body>
            <Card.Title>Economic Info</Card.Title>
            <Card.Subtitle>Bear, DE</Card.Subtitle>
            <ListGroup variant="flush">
              <ListGroup.Item>Median Income: $71,093</ListGroup.Item>
              <ListGroup.Item>Average Home Value: $200,600</ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
        <Card style={{width:'18rem'}}>
          <Card.Body>
            <Card.Title>Education Info</Card.Title>
            <Card.Subtitle>Bear, DE</Card.Subtitle>
            <ListGroup variant="flush">
              <ListGroup.Item>Elementary Students: 2,465</ListGroup.Item>
              <ListGroup.Item>Middle School Students: 1,829</ListGroup.Item>
              <ListGroup.Item>High School Students: 2,909</ListGroup.Item>
              <ListGroup.Item>High School Graduation Rate: 86.5%</ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
      </div>
    </div>
    </>
  )
}
