import React, {useState} from 'react';
import {Modal, Nav} from 'react-bootstrap';
import fb from '../images/fb.png';
import insta from '../images/insta.png';
import twit from '../images/twit.png';
import yt from '../images/yt.png';
import email from '../images/email.png';
import phone from '../images/phone.png';

export default function Connect() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return(
    <>
    <Nav.Link onClick={handleShow}>Connect</Nav.Link>
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Connect with Tom</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container" id="connectbox">
          <h4>Contact Me Directly</h4>
          <div className="row">
            <a href="mailto:emailaddress@email.com"><img src={email} alt="Email" id="emailbtn" /></a>
            <a href="tel:1234567890"><img src={phone} alt="Phone" id="phonebtn" /></a>
          </div>
          <hr />
          <h4>Socialize</h4>
          <div className="row">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><img src={fb} alt="Facebook" id="fbbtn" /></a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><img src={twit} alt="Twitter" id="twitbtn" /></a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><img src={insta} alt="Instagram" id="instabtn" /></a>
            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer"><img src={yt} alt="YouTube" id="ytbtn" /></a>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    </>
  )
}
