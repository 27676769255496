import React, {Component} from 'react';
import Slider from './components/slider';
import Mainnav from './components/mainnav';
import Backtext from './components/BackpageMain';
import Featurenav from './components/featurenav';
import Shoe from './components/shoe';
import Divider from './components/Divider';
import stat from './images/static.webp';

export default class Backpage5 extends Component {
  render(){
    return(
      <>
      <img src={stat} alt="Beautiful Delaware" id="headerimg" />
      <Mainnav />
      <Backtext />
      <Divider />
      <div className="container" id="infotext">
        <h1>Buyers Representation Brookside, DE</h1>
        <h4>I need help from the best buyer's representation in Brookside, DE.</h4>
        <p>If you are in need of buyer's representation in Brookside, DE, then you're in luck. Delaware Real Estate Hub is at your service and we have a background of offering a unique perspective when assisting people in finding the homes they want at a desirable value. Whether you're a first-time homebuyer, investor, or looking to move up in style, we can help you with whatever assistance you require.</p>
        <p>We can help place you wherever you want to be, whether you're looking for a single-family home starting at $200,000 or a luxurious estate for over $1M. To find the home of your dreams at your current options it's essential that you choose a real estate professional well-informed and determined to support you by staying in contact, keeping you knowledgeable, and negotiating furiously on your behalf.</p>
        <p>If you are looking for the leading options in your buyer's representation in Brookside, DE, I can assure you that our knowledge of this area will be advantageous in your search. By determining your requirements, budget, and neighborhoods of interest, we can further inform you about locations of schools, freeways, recreation, shopping, entertainment options, and community amenities. Then let us organize for you the latest MLS listings so that we can compile a list of properties to tour.</p>
        <p>Because buying a new home can be one of the most significant personal investments you'll ever make in your life, it's essential to prepare yourself with an action strategy. We also can help introduce you to a lender to get pre-approved for a loan, which can broaden your possibilities. Discover for yourself why there's no one better to represent you in your quest for your dream home. Communicate with us today.</p>
      </div>
      <Shoe />
      </>
    )
  }
}
