import React, {Component} from 'react';

class Secondtext extends Component {
  render(){
    return(
      <>
      <div className="container" id="infotext">
        <h1>Find Your Dream Home</h1>
        <p>If you're looking for your dream home in Northern Delaware, including New Castle, Wilmington, Bear, Bellefonte, and more, you've come to the right place! We're your one stop shop for everything in the New Castle County market. We have the knowledge, experience, and expertise in the Delaware market to get you the home you need.</p>
        <p>Tom has 19 years of experience in the New Castle market, and he can get you the home you want, and with his background in commercial contracting, he can even help you find a great deal on a home that may only need minor work done. Contact Tom today to get your new home in Delaware!</p>
      </div>
      </>
    )
  }
}

export default Secondtext;
