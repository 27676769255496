import React, {Component} from 'react';
import Slider from './components/slider';
import Mainnav from './components/mainnav';
import Backtext from './components/BackpageMain';
import Featurenav from './components/featurenav';
import Shoe from './components/shoe';
import Divider from './components/Divider';
import stat from './images/static.webp';

export default class Backpage4 extends Component {
  render(){
    return(
      <>
      <img src={stat} alt="Beautiful Delaware" id="headerimg" />
      <Mainnav />
      <Backtext />
      <Divider />
      <div className="container" id="infotext">
        <h1>Sell My Home North Star, DE</h1>
        <h4>Help sell my home in North Star, DE</h4>
        <p>"Please Help me sell my home home in North Star, DE!" If you want to sell your property, it's important not to go it alone. Attempting to sell without a real estate professional means an uphill battle, in which you could experience a long amount of time before selling and you could possibly walk away with less money than the current value. We are happy to announce that you will get the best possible results here, thanks to our dedication and knowledge of the local North Star, DE market.</p>
        <p>Our guidance enables you to achieve a straightforward and proven route of Selling your house. The median local price is $450,000 and you deserve for us to get you every dollar of it for you! There's never been a better time to sell and we are  happier than ever to stand by your side and streamline and simplify the sales process for you. Communicate with us soon to learn how we can get your home sold fast!</p>
        <p>I need you to sell my home in North Star, DE! Whatever you do, don't sell by yourself. Time and money are things you can't afford to lose, and when you try selling by yourself, you'll find everything to be overwhelming and stressful. Alternatively, an experienced real estate professional in the local market will tell you everything you need to know promptly, and you'll sell faster, and for far more money.</p>
        <p>Communicate with us and allow us to list your home or investment property. There are 24 homes for sale in the North Star, DE area . Why should you need to contend with the obstacles of the local market on your own? What we do to help people make the most of their transactions gives our clients confidence and peace of mind. Contact us when it's convenient for you and we will be happy to schedule a free seller's consultation!</p>
      </div>
      <Shoe />
      </>
    )
  }
}
