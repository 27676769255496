import React, {Component} from 'react';
import Mainnav from './components/mainnav';
import Shoe from './components/shoe';
import Divider from './components/Divider';
import area from './images/bear.jpg';
import AreaInfo from './components/AreaInfo';
import FareaText from './components/Fareatext';
import Capture from './components/captureform';

export default function Farea() {
  return(
    <>
    <Mainnav />
    <div class="container" id="featurebox">
      <h2>Featured Area: Bear, DE</h2>
    </div>
    <img src={area} alt="Area Name" id="fareaimg" />
    <Divider />
    <div className="container">
      <AreaInfo />
      <FareaText />
      <div id="capture">
        <Capture />
      </div>
    </div>
    <Shoe />
    </>
  )
}
