import React, {Component} from 'react';
import Slider from './components/slider';
import Mainnav from './components/mainnav';
import Backtext from './components/BackpageMain';
import Featurenav from './components/featurenav';
import Shoe from './components/shoe';
import Divider from './components/Divider';
import stat from './images/static.webp';

export default class Backpage1 extends Component {
  render(){
    return(
      <>
      <img src={stat} alt="Beautiful Delaware" id="headerimg" />
      <Mainnav />
      <Backtext />
      <Divider />
      <div className="container" id="infotext">
        <h1>Free Market Analysis Pennyhill Terrace</h1>
        <h4>Schedule your free market analysis in Pennyhill Terrace with the Delaware Real Estate Hub today.</h4>
        <p>Your listing price comes from my free market analysis in Pennyhill Terrace. And your smooth home sale closing comes from my 4.5% commission seller services. Your home’s value depends on many aspects. But when you let me handle the inspection and valuation, you can focus more on your pending relocation. Let’s chat about your selling timeframe, motives, and moving plans. Once I determine your home’s value, I’ll help you complete the sale for an optimum return with a stress-free experience.</p>
        <p>We are the Delaware Real Estate Hub, and we'll use the most current marketing techniques to market your home. Your listing will have visibility on the MLS, websites, social media, emails, and other marketing methods. We can also invite third-party opinions from attorneys and contractors, as well as set up tours for interested buyers. Your audience wants to see a listing that appeals to them; they want attention to detail with several photos to showcase your home.</p>
        <p>Selling your home requires getting a free market analysis in Severn. As the best listing specialist in New Castle County, I’ll help you understand your home’s value when it’s time to sell it. With this complimentary CMA, you’ll get an inspection of your home’s structure, amenities, age, rooms quantity, your neighborhood, and more to determine its ideal listing price. But knowing your home’s value is only the beginning.</p>
        <p>When you prepare your home for the market, be mindful of buyer interests. You may need to fix up some weaknesses to give your home a better value. But you’ll also want to highlight the best features about it so those home seekers can envision themselves living in it. Staging and curb appeal go a long way when inviting desirable buyer offers. Collaborate with me today. Your desired quality results depend on it. Give me a call soon.</p>
      </div>
      <Shoe />
      </>
    )
  }
}
