import React from 'react';
import Mainnav from './components/mainnav';
import Maintext from './components/maintext';
import AboutText from './components/abouttext';
import Shoe from './components/shoe';

export default function AboutMe() {
  return(
    <>
    <Mainnav />
    <div className="container">
      <Maintext />
      <AboutText />
    </div>
    <Shoe />
    </>
  )
}
