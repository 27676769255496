import React from 'react';
import sellright from '../images/sellright.jpg';

export default function SellersRight(){
  return(
    <>
    <div className="container" id="buybox">
      <div className="row">
        <div className="col-8">
          <h1>The Top 3 Things Buyers Want</h1>
          <h4>These are the most common things buyers ask me for in New Castle.</h4>
          <p><strong>Location</strong></p>
          <p>Today's buyers are all about convenience, and there's no better way to get that than a great location. Being somewhere that will reduce a commute time, make running to the store easier, or making it simple to get to the local "downtown" area will go a long way in having your home sell quick and easy.</p>
          <p><strong>Storage</strong></p>
          <p>Most buyers these days are shopping for a place to keep their stuff as well as their family. Most prevalent in the younger homebuyer, hobbies and projects are the big thing, and they need somewhere to keep and work on those things. Large garages, walk-in pantries, sheds, and closets are all in high demand, so emphasize those things in your home.</p>
          <p><strong>Layout</strong></p>
          <p>Seniors are looking to move too, and they're looking for easy access to their new home. They want smaller spaces, older style layouts (such as the ever popular ranch-style), no exterior steps, and anything to reduce maintenance on the home.</p>
        </div>
        <div className="col-4">
          <img src={sellright} alt="Home Buyers" id="buyright" />
        </div>
      </div>
    </div>
    </>
  )
}
