import React from 'react';
import buyleft from '../images/buyleft.jpg';

export default function BuyersLeft(){
  return(
    <>
    <div className="container" id="buybox">
      <div className="row">
        <div className="col-4">
          <img src={buyleft} alt="Home Buyers" id="buyleft" />
        </div>
        <div className="col-8">
          <h1>Save Money on Your New Delaware Home</h1>
          <h4>How to save money on your home purchase in New Castle County.</h4>
          <p>Do you want to save on your new home? Of course you do! Here are some ways I've found to help get down that sale price on your dream home.</p>
          <p><strong>Cosmetic Issues</strong></p>
          <p>Lots of first-time buyers look at a home, and walk away from a deal because of something as simple as the paint colors. Don't do that! You can always paint over that color you don't like, so why settle for a less-than-perfect floor plan? A little elbow grease can greatly reduce the hassle and price of your new home.</p>
          <p><strong>Keep That Credit Score Up</strong></p>
          <p>A better rate on your mortgage means lower costs for your new home over time, so make sure to keep that credit score as high as possible leading up to your home purchase. Little things like opening a new credit card or getting a new car loan can cause a dip in your credit score for up to two years, so try and avoid them right before you purchase a new home.</p>
          <p><strong>Shop Around for Your Mortgage</strong></p>
          <p>Just like a new TV, you can get a better deal on your mortgage by exploring your options. Get a rate quote from different lenders on the same day, so you can make the best possible comparison between rates. Even a 0.1% difference in your rate can save you thousands of dollars over the length of your mortgage, so shop around!</p>
          <p><strong>Work with an Agent</strong></p>
          <p>Our experience navigating the real estate market will save you time and money in your home transaction. Let me help you get into your new home as quickly and painlessly as possible!</p>
        </div>
      </div>
    </div>
    </>
  )
}
