import React from 'react';

export default function AboutCompany(){
  return(
    <>
    <h1>The Delaware Real Estate Hub</h1>
    <h4>Your Source for Real Estate in Delaware</h4>
    <p>Welcome to the premier resource for all real estate information and services in northern Delaware. We hope you enjoy your visit and explore everything our website has to offer, including information for homebuyers and sellers, and more About Us, your professional New Castle home source.</p>
    <p>Looking for a new home? Use our Home Finder form and we'll conduct a personalized search for you.</p>
    <p>If you're planning to sell your home in the next few months, nothing is more important than knowing a fair asking price. We would love to help you with a FREE Market Analysis. We will use comparable sold listings to help you determine the accurate market value of your home.</p>
    </>
  )
}
