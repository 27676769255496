import React from 'react';

export default function FhomeText() {
  return(
    <>
    <h1 id="fhometitle">123 Address Way, Cityname, ST 83745</h1>
    <h4>Blurb About Home</h4>
    <p>I love cheese, especially cheesecake cheese strings. Airedale jarlsberg smelly cheese halloumi bocconcini smelly cheese croque monsieur edam. Monterey jack pepper jack blue castello blue castello brie hard cheese taleggio hard cheese. Feta edam chalk and cheese goat pepper jack cheeseburger cheese triangles stilton. Goat st. agur blue cheese cut the cheese cheddar cheesy feet feta lancashire swiss. Everyone loves cheese strings.</p>
    <p>Cheeseburger blue castello mozzarella. Cut the cheese halloumi cheese slices camembert de normandie emmental emmental roquefort emmental. Stilton mascarpone cauliflower cheese rubber cheese camembert de normandie bavarian bergkase hard cheese cheese on toast. Feta cheesy grin cheesecake monterey jack swiss bocconcini cheese and biscuits paneer. Mozzarella stilton boursin cheeseburger halloumi fondue danish fontina cheese and biscuits. Brie squirty cheese cheesecake bocconcini blue castello pepper jack fromage frais dolcelatte. Taleggio red leicester when the cheese comes out everybody's happy dolcelatte the big cheese airedale cheese strings cheese slices. Smelly cheese blue castello goat jarlsberg caerphilly when the cheese comes out everybody's happy queso.</p>
    <p>Cheese strings dolcelatte croque monsieur. Blue castello jarlsberg cheesecake pecorino cheese slices macaroni cheese cheesecake cheesecake. Cheese and wine everyone loves cream cheese manchego mascarpone hard cheese cream cheese caerphilly. Camembert de normandie cheese slices cheesecake danish fontina camembert de normandie edam danish fontina cheese slices. Fromage frais cut the cheese melted cheese queso queso st. agur blue cheese parmesan st. agur blue cheese. Paneer paneer.</p>
    </>
  )
}
