import React from 'react';

export default function Divider(){
  return(
    <>
    <div id="divider">
      <div className="container"></div>
    </div>
    </>
  )
}
