import React, {Component} from 'react';
import logo from '../images/logo.png';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Capture from './captureform';

class Featurenav extends Component {
  render(){
    return(
      <>
      <Navbar bg="secondary" expand="lg" id="featurenav">
      <div className="container">
        <Navbar.Brand href="/">Features</Navbar.Brand>
        <Navbar.Toggle aria-controls="main-nav" />
        <Navbar.Collapse id="main-nav">
          <Nav className="ml-auto">
            <NavDropdown title="Featured Articles" id="feature-drop">
              <NavDropdown.Item><Link to="/homesforsalecheyenne">Cheyenne Homes for Sale</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to="/listingagentaustin">Austin Listing Agents</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to="/whatsmyhomeworthhartford">What's My Home in Hartford Worth?</Link></NavDropdown.Item>
            </NavDropdown>
            <Link to="/featuredhome">Featured Listing</Link>
            <Link to="/featuredarea">Featured Area</Link>
            <Capture />
          </Nav>
        </Navbar.Collapse>
      </div>
      </Navbar>
      </>
    )
  }
}

export default Featurenav;
