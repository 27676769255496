import React from 'react';
import {Card, ListGroup} from 'react-bootstrap';

export default function HomeInfo() {
  return(
    <>
    <div className="container" id="homeinfo">
      <div className="row">
        <Card style={{ width: '24rem' }}>
          <Card.Body>
            <Card.Title>Exterior Info</Card.Title>
            <Card.Subtitle>123 Address Way</Card.Subtitle>
            <ListGroup variant="flush">
              <ListGroup.Item>Siding: Aluminium</ListGroup.Item>
              <ListGroup.Item>Lot Size: 0.2 Acres</ListGroup.Item>
              <ListGroup.Item>Landscape: Grass</ListGroup.Item>
              <ListGroup.Item>Extra: Shed</ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
        <Card style={{width:'24rem'}}>
          <Card.Body>
            <Card.Title>Interior Info</Card.Title>
            <Card.Subtitle>123 Address Way</Card.Subtitle>
            <ListGroup variant="flush">
              <ListGroup.Item>Flooring: Hardwood</ListGroup.Item>
              <ListGroup.Item>SQ FT: 1,981</ListGroup.Item>
              <ListGroup.Item>Appliances: Included</ListGroup.Item>
              <ListGroup.Item>Bedrooms: 3</ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
      </div>
    </div>
    </>
  )
}
