import React, {Component} from 'react';
import Slider from './components/slider';
import Mainnav from './components/mainnav';
import Backtext from './components/BackpageMain';
import Featurenav from './components/featurenav';
import Shoe from './components/shoe';
import Divider from './components/Divider';
import stat from './images/static.webp';

export default class Backpage1 extends Component {
  render(){
    return(
      <>
      <img src={stat} alt="Beautiful Delaware" id="headerimg" />
      <Mainnav />
      <Backtext />
      <Divider />
      <div className="container" id="infotext">
        <h1>Sellers Agent Pennyhill Terrace</h1>
        <h4>Where can I find a seller's agent for Pennyhill Terrace?</h4>
        <p>We can be your sellers’ agent in Pennyhill Terrace! We are the Delaware Real Estate Hub. Whether you’re coming back onto the market after listing with a previous broker, or you’re starting fresh, you should connect with my team and me today. We demonstrate great insight into the market, sharing creative ideas about marketing your home, and we know how to follow through on our promises.</p>
        <p>You won't be disappointed. Your sale will have its unique challenges. But we provide the insightful guidance you need to help you make the right decisions concerning price and sales approach. We beat the average time on the market in your neighborhood without any hiccups. Lean on our effective communication style to enjoy success. We have a unique combination of experience and spirit.</p>
        <p>As your sellers’ agent in Pennyhill Terrace, we'll prepare a complimentary comparative market analysis. Then we can discuss the staging of your home to assist with our real estate objectives. Our comprehensive advice and direction will be instrumental in helping you get the right value. You can take advantage of the best timing for your sale. We won't let possibility escape you when they appear.</p>
        <p>We're entirely ready to negotiate a successful opportunity that supports your long-term goals. It's because we put in the work to get your home staged, attend every private showing, wide-ranging marketing, and closing escrow successfully. You have options, and you'll always be aware of them as we go. Discover how hard we’re going to work for you by scheduling your no-cost seller’s consultation.</p>
      </div>
      <Shoe />
      </>
    )
  }
}
