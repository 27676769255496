import React from 'react';
import {Table} from 'react-bootstrap';

export default function HomeFeatures() {
  return(
    <>
    <h2>Home Features</h2>
    <Table striped bordered hover responsive size="sm">
      <thead>
        <tr>
          <th>Feature</th>
          <th>Value</th>
          <th>Quantity</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan="2">Built In</td>
          <td>1999</td>
        </tr>
        <tr>
          <td>Heat/AC</td>
          <td>Heat: Central</td>
          <td>AC: Central</td>
        </tr>
        <tr>
          <td>Parking</td>
          <td>Covered</td>
          <td>Garage</td>
        </tr>
        <tr>
          <td>Windows</td>
          <td>Double-Pane</td>
          <td>Vertical Blinds</td>
        </tr>
      </tbody>
    </Table>
    </>
  )
}
