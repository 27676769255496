import React from 'react';
import Mainnav from './components/mainnav';
import AboutCompany from './components/aboutco';
import Shoe from './components/shoe';
import logo from './images/aboutimg.jpg';

export default function Company() {
  return(
    <>
    <Mainnav />
    <div className="container" id="companytext">
      <img src={logo} alt="Company Logo" id="companyimg" />
      <AboutCompany />
    </div>
    <Shoe />
    </>
  )
}
