import React from 'react';
import buyright from '../images/buyright.jpg';

export default function BuyersRight(){
  return(
    <>
    <div className="container" id="buybox">
      <div className="row">
        <div className="col-8">
          <h1>Which New Castle Neighborhood Should I Move To?</h1>
          <h4>How to find the best neighborhoods to settle down in.</h4>
          <p>Everyone wants to move to an area with a great balance of homes and stores, but it can be a little overwhelming. Here are some tips I've found to get that perfect mix of life and liberty.</p>
          <p><strong>New Construction</strong></p>
          <p>Look for areas that have new homes AND new businesses being constructed. This is a great indicator that the area is being actively improved, and that there's enough interest in the area to ensure that there will be new and exciting things for the forseeable future.</p>
          <p><strong>Follow the Trends</strong></p>
          <p>When you're trying to pick an area to move to, look for trendsetter type businesses: juice bars, vegan/organic restaurants, and boutique type stores. These "hipster" businesses show that there's an interest in the area from younger generations, and that the area will continue to develop over time.</p>
          <p><strong>The Three L's</strong></p>
          <p>You've probably heard of the "Three L's of Real Estate" before: Location, Location, and Location. This is especially true for homebuyers looking for the next big thing. Communities right on the edge of major metro areas have greater interest levels, usually because prices tend to be lower, while still being close to the action in the city itself.</p>
        </div>
        <div className="col-4">
          <img src={buyright} alt="Home Buyers" id="buyright" />
        </div>
      </div>
    </div>
    </>
  )
}
