import React from 'react';
import {Card, ListGroup} from 'react-bootstrap';

export default function MAreaInfo() {
  return(
    <>
    <div className="container" id="homeinfo">
      <div className="row">
        <Card style={{ width: '24rem' }}>
          <Card.Body>
            <Card.Title>Population Info</Card.Title>
            <Card.Subtitle>New Castle County</Card.Subtitle>
            <ListGroup variant="flush">
              <ListGroup.Item>Total Population: 523,008</ListGroup.Item>
              <ListGroup.Item>Population Density: 1,174.3 persons/sq. mi.</ListGroup.Item>
              <ListGroup.Item>Workforce: 304,913</ListGroup.Item>
              <ListGroup.Item>Homeowners: 366,628</ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
        <Card style={{width:'24rem'}}>
          <Card.Body>
            <Card.Title>Economic Info</Card.Title>
            <Card.Subtitle>New Castle County</Card.Subtitle>
            <ListGroup variant="flush">
              <ListGroup.Item>Unemployment: 6.32%</ListGroup.Item>
              <ListGroup.Item>Median Income: $53,543</ListGroup.Item>
              <ListGroup.Item>Average Home Value: $136,000</ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
        <Card style={{width:'24rem'}}>
          <Card.Body>
            <Card.Title>Education Info</Card.Title>
            <Card.Subtitle>New Castle County</Card.Subtitle>
            <ListGroup variant="flush">
              <ListGroup.Item>High School Graduates: 85.5%</ListGroup.Item>
              <ListGroup.Item>College Graduates: 29.5%</ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
        <Card style={{width:'24rem'}}>
          <Card.Body>
            <Card.Title>Other Info</Card.Title>
            <Card.Subtitle>New Castle County</Card.Subtitle>
            <ListGroup variant="flush">
              <ListGroup.Item>Mean Commute: 24.3 mins</ListGroup.Item>
              <ListGroup.Item>Land Area: 426 sq. mi.</ListGroup.Item>
              <ListGroup.Item>Growth: +4.5% per year</ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
      </div>
    </div>
    </>
  )
}
