import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import ConnectBot from './connectbot';

class Shoe extends Component {
  render(){
    return(
      <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-6" id="left">
              <ul>
                <li className="listhead">Site Nav</li>
                <li><Link to="/company">Brokerage</Link></li>
                <li><Link to="/featuredarea">Featured Area</Link></li>
                <li><Link to="/buyers">Buyers</Link></li>
                <li><Link to="/sellers">Sellers</Link></li>
                <li><Link to="/aboutme">About Tom</Link></li>
                <li><ConnectBot /></li>
              </ul>
            </div>
            <div className="col-6" id="left">
              <ul>
                <li className="listhead">External</li>
                
                <li><a href="https://en.wikipedia.org/wiki/New_Castle_County,_Delaware" target="_blank" rel="noopener noreferrer">New Castle County Wiki</a></li>
                <li><a href="https://en.wikipedia.org/wiki/Delaware" target="_blank" rel="noopener noreferrer">Delaware Wiki</a></li>
                <li><a href="https://datausa.io/profile/geo/new-castle-county-de">New Castle County Datasheet</a></li>
                <li><a href="https://datausa.io/profile/geo/delaware">Delaware Datasheet</a></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      </>
    )
  }
}

export default Shoe;
