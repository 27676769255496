import React, {Component} from 'react';
import Slider from './components/slider';
import Mainnav from './components/mainnav';
import Backtext from './components/BackpageMain';
import Featurenav from './components/featurenav';
import Shoe from './components/shoe';
import Divider from './components/Divider';
import stat from './images/static.webp';

export default class Backpage1 extends Component {
  render(){
    return(
      <>
      <img src={stat} alt="Beautiful Delaware" id="headerimg" />
      <Mainnav />
      <Backtext />
      <Divider />
      <div className="container" id="infotext">
        <h1>Buyers Representation 19804</h1>
        <h4>For buyer’s representation in 19804, get guidance from the information resources at the Delaware Real Estate Hub.</h4>
        <p>If you need buyer’s representation in 19804 while checking out its properties, you’ve found the top resource you can get for real estate services and info in this region. We know you want something best suited to your needs. So, with our knowledge, insight, unmatched professionalism, and a client-first approach, let us guide you through the transaction.  We are the Delaware Real Estate Hub, and the Delaware Real Estate Hub is here to help you.</p>
        <p>This zip code area spans Ashley Heights, Newport, Woodcrest and parts of Elsmere and Stanton. It includes Banning Regional Park, as well as a substantial portion of the Russell Peterson Wildlife Refuge. Most of the properties sit in the affluent master-planned community of Ashley Heights, which features a wide array of resorts, shopping, dining, and businesses. With our intimate knowledge, let’s talk more about the homes.</p>
        <p>Finding the right buyer’s representation in 19804 isn’t a challenge when you see the ways we can serve you. And for whatever you’d like to check out, we can show you the handful of homes for sale from $210K to $1.5M. Condos list from $100K to $250k, townhomes from $150K to $400k, and land from $50K to $2.85M. Let’s talk more about your needs and finances to determine a list of potential properties you’d like to check out, and we can tour whatever interests you.</p>
        <p>It doesn’t matter if you’re downsizing, moving up, investing, or buying for the first, second, or third time. We’ll still dedicate ourselves to helping you purchase the property you want. You’ll appreciate our honesty, hard work, and availability when you need us. We develop all our relationships on foundations of trust and respect, and yours will be no different. We’re ready to be of service to you and exceed your expectations. Call us today.</p>
      </div>
      <Shoe />
      </>
    )
  }
}
