import React, {Component} from 'react';
import Slider from './components/slider';
import Mainnav from './components/mainnav';
import Backtext from './components/BackpageMain';
import Featurenav from './components/featurenav';
import Shoe from './components/shoe';
import Divider from './components/Divider';
import stat from './images/static.webp';

export default class Backpage2 extends Component {
  render(){
    return(
      <>
      <img src={stat} alt="Beautiful Delaware" id="headerimg" />
      <Mainnav />
      <Backtext />
      <Divider />
      <div className="container" id="infotext">
        <h1>Belefonte, DE Luxury Properties</h1>
        <h4>Show me all the Bellefonte, DE luxury properties today!</h4>
        <p>If you're in the market for any of the Belefonte, DE luxury properties, let a knowledgeable professional like myself take you on the tour. I determine my success by how sufficiently I meet your needs. I'll work conscientiously to provide you with objective advice, honest answers, and relevant information about current trends and market conditions in this exciting area.</p>
        <p>I will provide you with full access to the top MLS listings of high-end homes in this prestigious area that allow for its residents to many attractions like golfing, parks, trails, and many more sites of interest, including shopping, dining, and recreation options in the area that are of the best experiences.</p>
        <p>Belefont, DE luxury properties homes currently list in a wide range for discerning buyers. With delicate features, lush landscaping, attractive floor plans, picturesque views, and so much more, you'll find just what makes you feel deluxe and comfortable. I offer unparalleled service to high-end home buyers all throughout the New Castle County area, and I love serving clients in this exciting market. If you want the highest standard of personalized service you deserve, complete with information about the neighborhoods and access to all the fantastic services I offer, give me a call today!</p>
      </div>
      <Shoe />
      </>
    )
  }
}
