import React, {Component} from 'react';
import Slider from './components/slider';
import Mainnav from './components/mainnav';
import Backtext from './components/BackpageMain';
import Featurenav from './components/featurenav';
import Shoe from './components/shoe';
import Divider from './components/Divider';
import stat from './images/static.webp';

export default class Backpage3 extends Component {
  render(){
    return(
      <>
      <img src={stat} alt="Beautiful Delaware" id="headerimg" />
      <Mainnav />
      <Backtext />
      <Divider />
      <div className="container" id="infotext">
        <h1>Sell My Home Fast Bear, DE</h1>
        <h4>Call me when you ask, who can sell my home fast in Bear, DE?</h4>
        <p>If you're asking, "how do I sell my home fast in Bear, DE," you will significantly benefit from the assistance of our qualified experts. We are the Delaware Real Estate Hub and we serve homeowners throughout the New Castle County area. Communicate with us today and we will help you obtain the maximum value for your property.</p>
        <p>Recent studies show that the median median home value here right now is $314,900 and is projected to continue to rise in the next year. You could be in an ideal position to get the maximum value for your property and we work hard to obtain the best deal for your home.</p>
        <p>When you want to know, "How do I sell my home fast in Bear, DE?" don't hesitate to ask us what steps you need to take to do so. We love sharing all that Bear, DE has to offer with new arrivals, and we'll find the best ways to market your home and the community to willing buyers. We pride ourselves on the service and dedication we offer each client. We can't wait to do the same for you.</p>
        <p>From every stage of your home sale, whether it's when we're determining your home's market value or negotiating with potential buyers, we'll be there to guide you to success. We know just how important this sale is to you and what it can mean for the next stage of your life. Communicate with us soon and we can begin to help you get the most you can out of your property.</p>
      </div>
      <Shoe />
      </>
    )
  }
}
