import React, {Component} from 'react';
import Slider from './components/slider';
import Mainnav from './components/mainnav';
import Backtext from './components/BackpageMain';
import Featurenav from './components/featurenav';
import Shoe from './components/shoe';
import Divider from './components/Divider';
import stat from './images/static.webp';

export default class Backpage1 extends Component {
  render(){
    return(
      <>
      <img src={stat} alt="Beautiful Delaware" id="headerimg" />
      <Mainnav />
      <Backtext />
      <Divider />
      <div className="container" id="infotext">
        <h1>Listing Specialist Bellefonte DE</h1>
        <h4>Point me toward a listing specialist in Bellefonte.</h4>
        <p>Do you need a listing specialist in Bellefonte DE? Here at Delaware Real Estate Hub, we’re prepared to help you through the entire process. We’re a bunch of brokers who find accountability through accessibility. We’ll do everything to make sure you’re educated and aware every step of the way. We’re not in the business of keeping sellers in the dark. We’re here to work for you, not the other way around.</p>
        <p>We know selling your home can be stressful. For many adults, it’s the most intimidating financial endeavor of their lives. We’ll prove it doesn’t have to be that way. Our website connects buyers to the biggest list of homes; they’ll be able to search like an agent. We want lots of potential buyers to see your listing, and that leads to more offers. We’ll give you options that are best for your family.</p>
        <p>A listing specialist is different than the agent you’ve worked with if you’ve ever bought a house. Both are real estate agents working toward the same transaction, but one might use anything you say against you. If you tell a buyer’s agent you haven’t had a single offer, they’ll interpret that to mean there’s something wrong with the home, and they’ll look elsewhere.</p>
        <p>You have many options when finding a listing specialist in Bellefonte DE. While this might be true, we’re the only ones who are here in your interest. Trust us to get your home up to showing quality, list it at a good value, and get you the best offer around. Why let anyone else try to sell your home? Call us today to see what we can do for you.</p>
      </div>
      <Shoe />
      </>
    )
  }
}
