import React, {Component} from 'react';
import {Carousel} from 'react-bootstrap';
import slide1 from '../images/slide1.jpeg';
import slide2 from '../images/slide2.jpeg';
import slide3 from '../images/slide3.jpeg';

class Slider extends Component {
  render(){
    return(
      <>
      <Carousel>
        <Carousel.Item>
          <img className="d-block w-100" src={slide1} alt="Featured Home" />
          <Carousel.Caption>
            <h4>933 Woods Rd, Bear, DE 19701</h4>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={slide2} alt="Featured Home" />
          <Carousel.Caption>
            <h4>2311 Mousley Pl, Wilmington, DE 19810</h4>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={slide3} alt="Featured Home" />
          <Carousel.Caption>
            <h4>225 Romeo Dr, New Castle, DE 19720</h4>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      </>
    )
  }
}

export default Slider;
