import React, {Component} from 'react';
import Slider from './components/slider';
import Mainnav from './components/mainnav';
import Backtext from './components/BackpageMain';
import Featurenav from './components/featurenav';
import Shoe from './components/shoe';
import Divider from './components/Divider';
import stat from './images/static.webp';

export default class Backpage1 extends Component {
  render(){
    return(
      <>
      <img src={stat} alt="Beautiful Delaware" id="headerimg" />
      <Mainnav />
      <Backtext />
      <Divider />
      <div className="container" id="infotext">
        <h1>Free Market Analysis Ashley Heights DE</h1>
        <h4>Get your free market analysis in Ashley Heights, DE today!</h4>
        <p>Come to us for a free market analysis in Ashley Heights, DE. When it’s time to sell, and you need an accurate home value, here’s the best possible place you’ll find it. An evaluation from us includes an examination of properties currently on the MLS, recently sold, or that failed to sell. We’ll use these to help determine the best possible price for your listing, adjusting for any differences where necessary. To attract buyers with competitive offers, we need to make it fair for all parties.</p>
        <p>Working with us means getting an accurate valuation and detailed attractive listing. With the CMA, we’ll provide an inspection of residence type, unit quantity, living space size, and overall condition. Formal appraisers must follow strict industry and licensing guidelines. And they don’t work for free. But we know what buyers in this town are seeking and how to price your home reasonably and strategically.</p>
        <p>Getting a free market analysis in Ashley Heights, DE means that it’s the first step to the home selling process. But we can design a definite plan to move forward in which we’ll advise you to understand buyer situations and perspectives, as well as your selling motives and how to prepare. We’re ready to negotiate for the best price and terms towards a completed sale. You’ll have a successful experience by matching realistic goals with expert professionals like us.</p>
        <p>We are the Delaware Real Estate Hub and we are here to help you navigate the selling process with ease and comfort at your speed. You’ll get the valuation, but also the insight you need to respond to offers intelligently. Our approach includes win-win deals, personal touches, and positive results. Let’s talk more about your terms and requirements. Get in touch with us today to get started. Click link below for free market analysis in Ashley Heights DE.</p>
      </div>
      <Shoe />
      </>
    )
  }
}
